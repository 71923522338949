.spinner_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999;
}
