// Variable overrides

// Color system
// stylelint-disable
$white: #fff !default;
$gray-base: #2e2c2c !default;
$gray-100: #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200: #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300: #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400: #acb4bc !default; // lighten($gray-base, 60%);
$gray-500: #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600: #f4f8fb !default; // lighten($gray-base, 40%);
$gray-700: #5c6873 !default; // lighten($gray-base, 30%);
$gray-800: #2f353a !default; // lighten($gray-base, 10%);
$gray-900: #23282c !default; // lighten($gray-base, 5%);
$black: #000000 !default;
$border_bottom_color: #eae2e2 !default;

$black: #000000 !default;
$black-100: rgba(0, 0, 0, 0.85) !default; // lighten($black-base, 85%);
$black-200: rgba(0, 0, 0, 0.8) !default; // lighten($black-base, 80%);
$black-300: rgba(0, 0, 0, 0.7) !default; // lighten($black-base, 70%);
$black-400: rgba(0, 0, 0, 0.6) !default; // lighten($black-base, 60%);
$black-500: rgba(0, 0, 0, 0.5) !default; // lighten($black-base, 50%);
$black-600: rgba(0, 0, 0, 0.4) !default; // lighten($black-base, 40%);
$black-700: rgba(0, 0, 0, 0.3) !default; // lighten($black-base, 30%);
$black-800: rgba(0, 0, 0, 0.2) !default; // lighten($black-base, 10%);
$black-900: rgba(0, 0, 0, 0.05) !default; // lighten($black-base, 5%);

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);
$grays: () !default;

$blacks: () !default;
$blacks: map-merge(
  (
    "100": $black-100,
    "200": $black-200,
    "300": $black-300,
    "400": $black-400,
    "500": $black-500,
    "600": $black-600,
    "700": $black-700,
    "800": $black-800,
    "900": $black-900
  ),
  $blacks
);

$blacks: () !default;

// Theme Colors
$darkblue: #035372 !default;
$darkgray: #424245 !default;
$lightGray: #858585 !default;

$blue: #20a8d8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #ec5380 !default;
$red: #ca1111 !default;
$orange: #f8cb00 !default;
$yellow: #ffc107 !default;
$dark-green: #42a91d;
$green: #7dc754 !default;
$bright-green: #6bc718 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$light-blue: #63c2de !default;
$dark-gray: #646872;
$medium-gray: #e1e1e5;
$light-gray: #eef1f5;
$gray: #e0e7ed;
$medium-gray: #d2dce5;
$dark-gray: #6f6f6e;
$dark-gray: #c4cbd0;
$border-grey: #dcdde0;
$danger-pink: #ff3b80;

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "light-blue": $light-blue,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
  ),
  $colors
);

$primary: $darkblue !default;
// $secondary: $black !default;
$success: $green !default;
$info: $light-blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $light-gray !default;
$dark: $dark-gray !default;
$gray: $gray;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    // 'secondary': $secondary,
      "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);

$shadow-1: 0px 4px 13px 3px $gray;

$font-light: "Caros-light";
$font-medium: "Caros-medium";
$font-normal: "Caros-normal";
$Gilroy-normal: "Conv_Gilroy-Medium";

@mixin blockHeading {
  font-family: "Montserrat-bold";
  color: #58585a;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

// Media Query Breack Points

$mobile-width: 767px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-large-width: 1200px;

// @mixin tablet {
//   @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
//     @content;
//   }
// }

// @mixin desktop {
//   @media (min-width: #{$tablet-width}) {
//     @content;
//   }
// }
