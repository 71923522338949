// Here you can add other styles
@font-face {
  font-family: $font-light;
  src: url("../assets/fonts/Caros-Light.eot");
  src: local("☺"), url("../assets/fonts/Caros-Light.woff") format("woff"),
    url("../assets/fonts/Caros-Light.ttf") format("truetype"),
    url("../assets/fonts/Caros-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-medium;
  src: url("../assets/fonts/Caros-Medium.eot");
  src: local("☺"), url("../assets/fonts/Caros-Medium.woff") format("woff"),
    url("../assets/fonts/Caros-Medium.ttf") format("truetype"),
    url("../assets/fonts/Caros-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-normal;
  src: url("../assets/fonts/Caros.eot");
  src: local("☺"), url("../assets/fonts/Caros.woff") format("woff"),
    url("../assets/fonts/Caros.ttf") format("truetype"),
    url("../assets/fonts/Caros.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $Gilroy-normal;
  src: url("../assets/fonts/Gilroy-Medium.eot");
  src: local("☺"), url("../assets/fonts/Gilroy-Medium.woff") format("woff"),
    url("../assets/fonts/Gilroy-Medium.ttf") format("truetype"),
    url("../assets/fonts/Gilroy-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "src/scss/_variables";

@import "src/scss/_variables";

body {
  margin: 0;
  padding: 0;
  font-family: $font-medium, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  color: $lightGray;
  font-size: 0.875em;
  font-family: $font-light;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $font-medium;
}
.text-bold {
  font-family: $font-medium;
}
.text-light {
  font-family: $font-medium;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}
.no-padding-Left {
  padding-left: 0 !important;
}

a:hover {
  font-weight: 700;
  text-decoration: none;
}

body.modal-open {
  overflow: hidden !important;
}

.form-control::-webkit-input-placeholder {
  color: #c2c2c2;
}

.form-control::-moz-placeholder {
  color: #c2c2c2;
}
.form-control:-ms-input-placeholder {
  color: #c2c2c2;
}
.form-control:placeholder {
  color: #c2c2c2;
}

// Custom Button
.button_container {
  padding: 15px;
  display: block;
  border-radius: 6px;
  color: #fff;
  border: solid 2px $primary;
  background-color: $primary;
}

.button_container_custom {
  padding: 15px;
  display: block;
  border-radius: 6px;
  color: $primary;
  border: solid 2px $primary;
  background-color: #fff;
}

.react-bs-table-pagination {
  .dropdown {
    margin-left: 20px;
  }
}
